
let apiurl,staticurl;

// const ip = "192.168.1.65" 

// apiurl = `http://${ip}:8000/api`
// staticurl = `http://${ip}:8000/`

const url = "https://server.planetmusic.com.np"
apiurl = `${url}/api`
staticurl = `${url}/`



module.exports = {apiurl,staticurl}