import React, { useEffect, useState } from 'react'
import { useParams,useNavigate,Link } from 'react-router-dom'
import { apiurl,staticurl } from './apiurl'
import authfetch from './authfetch'
import Footer from "./Footer"
import ItemCard from './ItemCard'
import {
    SideBySideMagnifier,
  } from "sunzi-image-magnifiers";
import {BrandSelect,CategoryWithSubCategorySelect} from "./subcomponents/Selectors"
import Deletor from "./subcomponents/Deletor"

// sub component edit product detail modal
const EditProductDetail = (props) =>{
    const [brands,setBrands] = useState(null)
    const [categories,setCategories] = useState(null)
    const navigate = useNavigate()

    useEffect(()=>{
        if (props.product){
            document.querySelector(".new_prod_name").value = props.product.prod_name
            document.querySelector(".new_prod_desc").value = props.product.prod_desc
            document.querySelector(".edit-product-thumbnails").innerHTML = "<span class='note'>Note: no need to choose if main thumbnail is not to be changed</span>"
            document.querySelector("#editProductBrand").value = props.product.brand_ref._id
            if (props.product.subcategories_ref){
                document.querySelector("#editProductCategory").value = "S"+props.product.subcategories_ref
                
            }else{
                document.querySelector("#editProductCategory").value = "M"+props.product.category_ref._id

            }

            document.querySelectorAll("input[name='new_product_thumbnail']").forEach(elem=>{
                // uncheck all fields because radio remains checked after thumbnail update
                elem.checked=false         
            })
        }
        
    },[props.product,brands,categories])

    useEffect(()=>{
        authfetch(apiurl+"/brands").then(resp=>resp.json())
        .then(data=>{
            setBrands(data.data)
        })
        .catch(err=>{
            console.log(err)
        })

        authfetch(apiurl+"/categories").then(resp=>resp.json())
        .then(data=>{
            setCategories(data.data)
        }).catch(err=>console.log(err))

    },[])

    const handleEditProduct = (e) =>{
        e.preventDefault()
        const form = document.querySelector(".editProductForm")
        if (!form.checkValidity()){
            form.reportValidity()
            return
        }

        const newthumbnailselectedArr = document.querySelectorAll('input[name="new_product_thumbnail"]')
        let images = props.product.prod_img; //will contain update_prod_images after bring the selected image to index 0
        newthumbnailselectedArr.forEach(thumb=>{
            if (thumb.checked){
                // newthumbnailname = thumb.value
                const index = props.product.prod_img.indexOf(thumb.value)
        
                
                if (index !== -1) {
                    const matchingFilename = images[index];
                    images.splice(index, 1); // Remove the matching filename from its original position
                    images.unshift(matchingFilename); // Add the matching filename at the beginning of the array
                }
            }
        })

        const formData = new FormData()
        formData.append("_id",props.product._id)
        formData.append("new_prod_image_add",document.querySelector("#addNewProdImage").files[0])
        formData.append("prod_name",document.querySelector(".new_prod_name").value)
        formData.append("prod_desc",document.querySelector(".new_prod_desc").value)
        formData.append("prod_img",JSON.stringify(images))
        formData.append("prod_thumbnail",images[0].replace("/products/","/thumbnails/"))
        formData.append("brand_ref",document.querySelector("#editProductBrand").value)
        formData.append("category_ref",document.querySelector("#editProductCategory").value)

        // console.log(formData)
        const headers = {
            "Authorization":localStorage.getItem("token")
        }

        fetch(apiurl+"/products",{
            method:"PUT",
            headers:headers,
            body: formData
        }).then(res=>res.json())
        .then(data=>{
            props.setProductRefresh(props.productRefresh + 1)
            alert(data.message)
        })
        .catch(err=>{
            console.log(err)
        })
    }
    const handleDeleteProduct = (e) =>{
        e.preventDefault()
        const confirm = window.confirm(`are you sure you want to delete ${props.product.prod_name}`)
        if (!confirm) return
        authfetch(apiurl+"/products",navigate,{
            method:"DELETE",
            body: JSON.stringify({_id:props.product._id})
        }).then(res=>res.json())
        .then(data=>{
            // console.log(data)
            alert(data.message)

            // show latest products when no project if found
            const similarProductCount = 6
            authfetch(`${apiurl}/search`,navigate,{
                method:"POST",
                body: JSON.stringify({
                    productPerPage:similarProductCount,
                    latest:true
                })
            })
            .then(res=>res.json())
            .then(data=>{
                props.setSimilarProduct(data.data)


            })
            .catch(err=>{
                console.log(err)
            })
        })
        .catch(err=>{
            console.log(err)
        })
    }

    return (
        <>
        <div className="modal fade" id="editProductDetailModel" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Product details</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <form className='editProductForm'>
                    <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">Name</span>
                    <input required type="text" className="form-control new_prod_name" placeholder="Product Name" aria-label="Product Name" aria-describedby="basic-addon1"/>
                    </div>
                    <div className="input-group">
                    <span className="input-group-text">Desc&nbsp;</span>
                    <textarea required className="form-control new_prod_desc" placeholder='Product Description' aria-label="Product Description" style={{height:"150px"}}></textarea>
                    </div>
                    {/* select brand here */}
                    
                    <div class="input-group my-2">
                        <span class="input-group-text" id="basic-addon1">Brand</span>
                        <BrandSelect brandId="editProductBrand" brands={brands} />
                    </div>

                    {/* select category here */}
                    <div class="input-group my-2">
                        <span class="input-group-text" id="basic-addon1">Category</span>
                        <CategoryWithSubCategorySelect
                        firstOption="Select Category/SubCategory"
                        disableFirstOption={true}
                        selectId="editProductCategory" categories={categories} />
                    </div>
                    
                    <div className='edit-product-thumbnails'>                        
                    </div>
                    <div>
                        {props.product && props.product.prod_img.map((img,index)=>(
                            <>
                                <div className=''>
                                    <input type='radio' value={img} name="new_product_thumbnail" />
                                    <img src={staticurl + img} alt="prodThumbnail" width="85" />
                                    {/* index to determine if the image is thumbnail index=0 will be thumbnail */}
                                    <Deletor 
                                        img={img}
                                        prodId={props.product._id}
                                        index={index}
                                        productRefresh={props.productRefresh}
                                        setProductRefresh={props.setProductRefresh} />
                                    
                                </div>
                                
                            </>
                        ))}
                        <div className="my-3">
                            <span>Add New Image(if any):</span>
                            <input className="form-control newBrandLogo" type="file" id="addNewProdImage"/>
                        </div>
                    </div>

                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={handleDeleteProduct}><i className="fa-solid fa-trash-can"></i> Delete Product</button>
                    <button type="button" className="btn btn-success" onClick={handleEditProduct}><i className="fa-solid fa-floppy-disk"></i> Edit changes</button>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}



export default function IndividualProduct() {
    const params = useParams()
    const [productId,setProductId] = useState(params.productId)
    const [product,setProduct] = useState(null)
    // eslint-disable-next-line
    const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches)
    const [mainImage,setMainImage] = useState(null)
    const [fetchedBrandproduct,setfetchedBrandproduct] = useState(null)
    const [similarProduct,setSimilarProduct] = useState(null)
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    // productRefresh when edit product is done, just increase the value to trigger useeffect
    const [productRefresh,setProductRefresh] = useState(1)

    // const firstRender = useRef({productFirstRender:true,brandFirstRender:true})

    const navigate = useNavigate()


    const handleGalleryImgClick = (url) =>{
        // alert(url)
        setMainImage(url)
    }
    useEffect(()=>{
        // alert("prod changed")
        setProductId(params.productId)
        // alert(params.productId)
    },[params.productId])

    useEffect(() => {
            authfetch(`${apiurl}/products/get/${productId}`)
            .then(res=>res.json())
            .then(data=>{
                if (data.productFound === 0){
                    document.querySelector(".container").innerHTML = "<h1>product not available or has been deleted</h1>"
                }
                // add to recently viewed page if not present
                if (localStorage.getItem('recentviews')){
                    const recentviewsJson = JSON.parse(localStorage.getItem('recentviews'))
                    let recentviewsMap = new Map(recentviewsJson)
                    
                    if (recentviewsMap.has(data.data._id)){
                        recentviewsMap.delete(data.data._id)
                        
                    }
                    recentviewsMap = new Map([[data.data._id, {_id:data.data._id,prod_name:data.data.prod_name,prod_thumbnail:data.data.prod_thumbnail,timestamp: new Date().getTime()}], ...recentviewsMap]);
                    localStorage.setItem('recentviews',JSON.stringify(Array.from(recentviewsMap)))

                }
                else{
                    let recentviewsMap = new Map()
                    recentviewsMap.set(data.data._id,{
                        _id:data.data._id,
                        prod_name:data.data.prod_name,
                        prod_thumbnail:data.data.prod_thumbnail,
                        timestamp: new Date().getTime()
                    })
                    localStorage.setItem('recentviews',JSON.stringify(Array.from(recentviewsMap)))
                }

                setProduct(data.data)                
                setMainImage(data.data.prod_img[0])

                

            })
            .catch(err=>{
                console.log(err)
            })
             
        if (localStorage.getItem("token")){
            setIsLoggedIn(true)
        }
    }, [productId,productRefresh])
    
    useEffect(() => {
        // fetch products related to brand once main product is fetched
        if (product){
            // 3 for lg devices to cover the space and 4 for sm and xs devices row-cols-2
            const productPerPage = matches ? 2 : 4;
            if (product.brand_ref !== null){
                authfetch(`${apiurl}/brands/getbrandproduct`, navigate, {
                    method: "POST",
                    // just need to show latest 2 product from the brand for this page.
                    // exclude the current product (same product should not be displayed twice)
                    body: JSON.stringify({ _id: product.brand_ref._id,pageNumber:1,productPerPage,exclude:product._id}),
                })
                .then((res) => res.json())
                .then((data) => {
                    setfetchedBrandproduct(data)
                })
                .catch((err) => console.log(err));

            }
            
            // similar product fetch
            const similarProductCount = 6
            authfetch(`${apiurl}/search`,navigate,{
                method:"POST",
                body: JSON.stringify({
                    category_ref: product.category_ref._id,
                    productPerPage:similarProductCount,
                    excludeProductId:product._id,
                    latest:true
                })
            })
            .then(res=>res.json())
            .then(data=>{
                
                setSimilarProduct(data.data)


            })
            .catch(err=>{
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [product,productId]);

    

    return (
        <>
            <div className='container mb-4' style={{minHeight:"50vh"}}>
                <EditProductDetail setSimilarProduct={setSimilarProduct} product={product} setProductRefresh={setProductRefresh} productRefresh={productRefresh} />
                <h4 className='m-2'>
                {product && product.prod_name} 
                {isLoggedIn && (
                    <>
                    <sup><i className="fa-solid fa-pen-to-square cursor-pointer" data-bs-toggle="modal" data-bs-target="#editProductDetailModel"></i></sup>
                    </>
                )}
                </h4>
                {product && 

                // <div className='row row-cols-1 row-cols-lg-2 row-cols-md-2'>
                <div className='row'>
                    {/* <div className='col'> */}
                    <div className='col col-lg-8 col-12'>
                        <div className='magnifier' style={{border:"2px solid #a2a2a2"}}>
                            <SideBySideMagnifier
                                // style={{height:matches?"500px":"100%",width:matches?"500px":"100%"}}
                                style={{height:"50%"}}
                                imageSrc= {staticurl + mainImage}
                                imageAlt="Example"
                                alwaysInPlace={true}
                                overlayBoxImageSize="5000px"
                                // largeImageSrc="./large-image.jpg" // Optional
                                />
                            <div className='gallery cont'>
                                {/* {alert(product.prod_img.length)} */}
                                {product.prod_img.length!==1 && product.prod_img.map(img=>(
                                    <div className='gallery-item c1'>
                                        <img src={staticurl+img.replace("/products/","/thumbnails/")} alt='phto' style={{height:"80px","width":"auto"}} onClick={()=>handleGalleryImgClick(img)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='col text-justifiy'>
                        <h3 className='mt-3 heading-font'>About the product</h3>
                        <div className="product-description" style={{lineHeight:"2",width:"100%",overflowY:"scroll"}}>
                            {/* <pre style={{overflowWrap:"normal"}}> */}
                            {product.prod_desc}

                            {/* </pre> */}
                        </div>
                        <hr />
                        <div style={{backgroundColor:"beige",paddingButtom:"0px!important"}} className='px-2'>
                        {product.brand_ref &&
                        <>
                        <h4 className='pt-3 heading-font'>More From <span className='text-decoration-underline'><Link to={`/brands/${product.brand_ref.brandName}`}>{product.brand_ref.brandName}</Link></span></h4>
                        <div className='row row-cols-md-2 row-cols-lg-2 row-cols-2'>
                            {fetchedBrandproduct && fetchedBrandproduct.data.map(elem=>(
                                <div className='col mb-4'>
                                <ItemCard img={elem}/>

                                </div>
                            ))}
                        </div>
                        </>
                        }
                        </div>
                    </div>
                </div>
                }
                
            </div>
            <section className='mt-5'>
                <div className='container'>
                    <h3>Similar Products <span style={{fontSize:"16px"}}></span></h3>
                    <div className='row row-cols-md-4 row-cols-lg-6 row-cols-2'>
                        {similarProduct && similarProduct.map(elem=>(
                            <div className='col mb-4'>
                            <ItemCard img={elem}/>

                            </div>
                        ))}
                    </div>

                </div>

                </section>

            <Footer />
        </>
        
		
    )
}
