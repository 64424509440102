import React from 'react';
import { apiurl } from '../apiurl';
import authfetch from '../authfetch';
import { useNavigate } from 'react-router-dom';

// takes file url through the props and sends a delete request to the server to delete that file
// props takes {
//     img: product image url,
//     prodId: product ko _id,
//     index: prod_img ko index, 0 for thumbnail image
//     productRefresh: change this value to refresh product ,
//     setProductRefresh: function to change productRefresh    
// }

export default function Deletor(props) {
    const navigate = useNavigate()
    const handleDelete = () =>{
        if (props.index === 0){
            alert("thumbnail image cannot be deleted without first changing thumbnail.")
            return
        }
        const payload = {
            img_to_delete:props.img,
            prodId:props.prodId
        }
        console.log(payload)
        authfetch(apiurl + "/products/deleteimg",navigate,{
            method:"DELETE",
            body: JSON.stringify(payload)
        })
        .then(res=>res.json())
        .then(data=>{
            console.log("got the data",data)
            alert(data.message)
            props.setProductRefresh(props.productRefresh + 1)
        })
        .catch(err=>{
            console.log("err occured",err)
        })

    }
  return (
    <>
      <i className="fa-solid fa-trash-can cursor-pointer" onClick={handleDelete}></i>
    </>
  );
}

// export default Deletor;
