import React from 'react'


// props should contain stateVariable uploadProgressValue
// for id provide progressBarId
// uploadProgressValue should be state variable
export default function UploadProgressBar(props) {
  return (
    <div className='progressbar' id={props.progressBarId} style={{display:"none"}}>
            <progress value={props.uploadProgressValue}></progress>
            {(props.uploadProgressValue*100).toFixed(2)} %
    </div>
  )
}
