import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import authfetch from './authfetch'
import { apiurl } from './apiurl'
import { Link, useNavigate } from 'react-router-dom'

// all category page /categories
export default function Brands() {
    const [categories, setCategories] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        authfetch(`${apiurl}/categories`,navigate).then(resp=>resp.json())
        .then(data=>{
            const categories = data.data
            // console.log(categories)
            const groupedCategories = categories.reduce((acc,category)=>{
                const firstLetter = category.category_name[0].toUpperCase()
                if (!acc[firstLetter]){
                    acc[firstLetter] = []
                }
                acc[firstLetter].push(category)
                return acc
            },{});
            setCategories(groupedCategories)
        })
        .catch(err=>console.log(err))
    }, [])

  return (
    <>
    <div className='container'>
        <div className=''>

<h4 className='inunderline my-3 heading-font'>Browse Categories</h4>
{ 
    categories && Object.keys(categories).sort().map(letter => {
        return (
            <div className='' key={letter}>
                <h5 className='underline'>{letter}</h5>
                <div>
                {categories[letter].map(elem => (
                    <>
                        <Link to={`/category/${elem._id}`} className='list-brand-name heading-font font-16'>
                        <strong><u>{elem.category_name}</u></strong>
                        </Link>
                        <div key={elem._id} className='row row-cols-2 row-cols-md-4 row-cols-lg-6 mx-2 mb-4'>
                            
                                {elem.subcategories && elem.subcategories.map(subcat=>(
                                    <div className='col mb-2'>
                                        <Link to={`/category/${subcat._id}`} className='list-brand-name'>
                                        {subcat.subcategory_name}
                                        </Link>                                    
                                    </div>
                                ))} 
                        </div>
                        
                    </>
                ))}
                </div>

                <hr style={{float:"clear"}} />
            </div>
        );
    })
}
        </div>



    </div>
    <Footer />
    </>
  )
}
