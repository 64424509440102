import React from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'

const telephone = (num)=>{
  return (
    <>
        <a href={`tel:${num}`} >{num}</a>

    </>
  )
}

const locationBtn = (url) =>{
  return (<>
    <a href={url} target='_blank'>
      <button className='btn btn-outline-danger'>View Location</button>
    </a>
  </>)
}

const locationBox = (location,sublocation,numbers,mapUrl) => {
  return (
  <div className='col my-2'>
    <div className='card box'>
    <div className='locbox text-center'>
      <h1>
        <i className="fa-solid fa-location-dot"></i>
      </h1>
      <div className='' style={{color:"#000"}}>
        <div>{location}</div>
        <div>({sublocation})</div>
        {/* <span>{telephone("014799242")}, {telephone("9803389427")}, {telephone("9865689")}</span> */}
        {numbers.map((number,index)=>(
          <React.Fragment key={index}>
            {telephone(number)}
            {index !== numbers.length - 1 && ', '}

          </React.Fragment>
        ))}
        <div className='mt-2'>
          {locationBtn(mapUrl)}
        </div>
      </div>
    </div>
    
    </div>
  </div>
  )
}

export default function Contact() {
  
  return (
    <>
    <div className='container'>
      <h3 className='inunderline heading-font'>Contact us</h3>
      <h4 className='text-center heading-font'>Our Branches</h4>
      <div className='row row-cols-lg-4 row-cols-md-2 row-cols-1 my-2'>
        
        {locationBox("New Baneshwor","Burger House Building",["014799242","9803389427","9841432217"],"https://goo.gl/maps/8CmRvB97f1k3LETy5")}
        {locationBox("Chabahil, Mitrapark","opposite of Nepal Telecom",["9849042759","9843285095"],"https://goo.gl/maps/2XaqKJkrQhchytP26")}
        {locationBox("New Thimi","Balkumari Colony",["9863655377","9843633781"],"https://goo.gl/maps/J7d4Z2hM6iD82pe67")}
        {locationBox("Pokhara","Aalu Maila chowk",["9843527353"],"https://goo.gl/maps/pGkNXnx84QLmw85e6")}
       
      </div>
      <div className='map mt-4'>
        <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1YnLPdqtkQdje5IFAUgTmsQ3xXwXMsDI&ehbc=2E312F" style={{"width":"100%","height":"500px"}}></iframe>
      </div>

     
    </div>
    <Footer />
    </>
  )
}
