import React,{useEffect, useState} from 'react'
import Navbar from './components/Navbar'
import "./App.css"
import { 
  BrowserRouter as Router,
  Routes,
  Route
 } from 'react-router-dom'
import Home from './components/Home'
import Contact from './components/Contact'
import Brands from './components/Brands'
import Category from './components/Category'
import CategoryStore from './components/CategoryStore'
import AdminLogin from './components/AdminLogin'
import AdminPanel from './components/AdminPanel'
// import authfetch from './components/authfetch'
import { apiurl } from './components/apiurl'
import IndividualBrandDesc from './components/IndividualBrandDesc'
import IndividualProduct from './components/IndividualProduct'
import Search from './components/Search'
import RecentView from './components/RecentView'

export default function App() {
  const [navLocation,setNavLocation] = useState("/")

  const [categories,setCategories] = useState(null) 
  useEffect(() => {
    fetch(`${apiurl}/categories`)
    .then(res=>res.json())
    .then(data=>setCategories(data.data))
    .catch(err=>console.log(err))

    setNavLocation(window.location.href.replace(/(http(s)*:\/\/[A-Za-z0-9:.]+)/g,""))
  }, [])
  
  
  return (
    <>
    <marquee direction="right" height="" className="marqueedeatils">
      <div className='' style={{display:"flex","justifyContent":"space-around","padding":"10px"}}>
      <div className='col'>
        Email: planetmusicktm@gmail.com &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div className='col'>
        Phone: 9801053815 &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div className='col'>
        Planet Music Nepal &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </div>
    
    </marquee>
    <Router>
    <Navbar navLocation={{navLocation,setNavLocation}}/>
    <div className='alertbox'></div>
    <Routes>

    <Route path='/home' Component={Home}></Route>
    <Route path='/contact' Component={Contact}></Route>
    <Route path='/brands' Component={Brands}></Route>
    <Route path='/brands/:brandname' Component={IndividualBrandDesc}></Route>
    <Route path='/category' Component={Category}></Route>
    <Route path='/category/:category' element={<CategoryStore categories={categories}/>}></Route>

    <Route path="/" Component={Home}></Route>
    {/* <Route path="/admin" Component={AdminLogin}></Route> */}
    <Route path="/admin" element={<AdminLogin name={categories} />}></Route>
    <Route path="/adminpanel" Component={AdminPanel}></Route>
    <Route path="/search" Component={Search}></Route>
    <Route path="/product/:productId" Component={IndividualProduct}></Route>
    <Route path="/recentview" Component={RecentView}></Route>


    </Routes>



    </Router>
    
    </>
  )
}
