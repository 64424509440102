import React from 'react'
import { Link } from 'react-router-dom'
import { staticurl } from './apiurl'

export default function ItemCard(props) { 
    
  return (
    <>
      <Link to={`/product/${props.img._id}`} style={{textDecoration:"none"}}>
      <div className="card h-100" style={{display:props.display}}>
          <img src={staticurl+ props.img.prod_thumbnail} className="card-img-top" alt={props.img.prod_name} />
          <hr className='m-0 p-0' />
          <div className="card-body">
            <h5 className="card-title product-title-font blue-underline"><strong>{props.img.prod_name}</strong></h5>
          </div>
        </div>
      </Link>
          
    </>
  )
}
