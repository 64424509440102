import React, { useEffect, useState } from 'react'
import {Link,
  useNavigate
  // useLocation 
} from 'react-router-dom'
import { apiurl} from './apiurl'
import {CategoryWithSubCategorySelect} from './subcomponents/Selectors'

export default function Navbar(props) {
  // const [activeItem,setActiveItem] = useState(useLocation().pathname)
  const navigate = useNavigate()
  

  // const [categories,setCategories] = useState(null)
  const [isCollapsed,setIsCollapsed] = useState(true)
  const [isLoggedIn,setIsLoggedIn] = useState(false)
  const [activePage,setActivePage] = useState("/")

  const [refreshActive,setRefreshActive] = useState(0)
  
  // get few brands and few product related with brand using /brands/hoverbrands
  // const [hoverBrands,setHoverBrands] = useState(null)
  // const [hoverDataGrouped,setHoverDataGrouped] = useState(null)
  // const [hoverDataGroupedForCategory,setHoverDataGroupedForCategory] = useState(null)

  // get few categorys and few product realted with category using /category/hovercategory
  // const [hoverCategories,setHoverCategories] = useState(null)

  // use hoverBrandetails when use hover brand from .submenu ans show details side by side
  // const [hoverBrandDetails,setHoverBrandDetails] = useState(null)
  // const [hoverCategoryDetails,setHoverCategoryDetails] = useState(null)

  // const [brands,setBrands] = useState(null)
  const [categories,setCategories] = useState(null)
  // const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches)




  const handleNavbarclick = (location) =>{
    setIsCollapsed(true) //for mobile devices when nav link is clicked the window shoudl collapse
    setRefreshActive(refreshActive + 1) //update the active class in navbar when click
  }

  



  useEffect(() => {
    if (localStorage.getItem("token")){
      setIsLoggedIn(true)
    }

    setActivePage(window.location.href.split("/").slice(-1)[0])

  }, [refreshActive])

  useEffect(()=>{
    // fetch(apiurl+"/brands").then(res=>res.json())
    // .then(data=>{
    //   setBrands(data.data)
    // })
    // .catch(err=>{
    //   console.log(err)
    // })

    fetch(apiurl+"/categories").then(res=>res.json())
    .then(data=>{
      setCategories(data.data)
    })
    .catch(err=>{
      console.log(err)
    })

    // fetch(apiurl+"/brands/hoverbrands")
    // .then(res=>res.json())
    // .then(data=>{
    //   setHoverBrands(data)
    // })
    // .catch(err=>console.log(err))

    // fetch(apiurl+"/categories/hovercategories")
    // .then(res=>res.json())
    // .then(data=>{
    //   setHoverCategories(data)
    // })
    // .catch(err=>console.log(err))

  },[])

  // useEffect(()=>{
  //   if (hoverBrands){
  //     const groupedData = hoverBrands.reduce((result, obj) => {
  //       const brandName = obj.brand_ref.brandName;
  //       if (!result[brandName]) {
  //         result[brandName] = [];
  //       }
  //       result[brandName].push(obj);
  //       return result;
  //     }, {});
  //     setHoverDataGrouped(groupedData)
  //   }
  // },[hoverBrands])

  // useEffect(()=>{
  //   if (hoverCategories){
  //     const groupedData = hoverCategories.reduce((result, obj) => {
  //       const category_name = obj.category_ref.category_name;
  //       if (!result[category_name]) {
  //         result[category_name] = [];
  //       }
  //       result[category_name].push(obj);
  //       return result;
  //     }, {});
  //     setHoverDataGroupedForCategory(groupedData)
  //   }
  // },[hoverCategories])


  // const handleMouseEnter = (e,data)=>{
  //   setHoverBrandDetails(data)
  // }

  // when hover category item update hovercategory detail to show
  // const handleMouseEnter2 = (e,data)=>{
  //   setHoverCategoryDetails(data)
  //   // console.log(data)
  // }

  const handleSearch = (e) =>{
    e.preventDefault()
    setIsCollapsed(true)
    const cat = document.querySelector("#formSearchCategory").value
    let categoryType;
    let searchquery = "";
    if (cat[0] ==="M"){
      categoryType = "Main"
      // alert("main category")
    }else if (cat[0] === "S"){
      categoryType = "Sub"
      // alert("sub category")
    }else{
      categoryType = "All"
      // alert("All category")
    }
    searchquery = document.querySelector("#search").value
    // alert("this is",searchquery)
    // if (!searchquery) return
    navigate(`/search/?q=${searchquery}&categorytype=${categoryType}&ref=${cat.slice(1,)}&p=1`)


  }



  return (
    <nav className="navbar sticky-top navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
      
  <div className="container-fluid">
    <Link className="navbar-brand my-1" to="/"><img src="/logo.png" style={{height:"75px",width:"120px"}} alt='planet music'></img></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setIsCollapsed(!isCollapsed)}>
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className={`collapse navbar-collapse ${isCollapsed ? '':'show'}`} id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className={`nav-link ${(activePage==="home" || activePage==="/" || activePage === "")?"active":""}`} aria-current="page" to="/home" onClick={()=>handleNavbarclick("home")}>Home</Link>
        </li>
        <li className="nav-item brands">
          <Link className={`nav-link ${(activePage==="brands" )?"active":""}`} to="/brands" onClick={()=>handleNavbarclick("brands")}>Brands</Link>
          {/* {matches &&
          <div className='submenu p-3'>
            <div className='row'>
              <div className='col-lg-3'>
              {hoverDataGrouped && Object.entries(hoverDataGrouped).map(([brand, BrandProducts]) => (
                <div className='anchordiv' key={brand}>
                  <a href={`/brands/${brand}`} onMouseEnter={(e) => handleMouseEnter(e, BrandProducts)}>
                    {brand}
                  </a>
                  <hr />
                </div>
              ))}

            </div>

              <div className='col-lg-9'>
              {hoverBrandDetails && hoverBrandDetails.map(elem => (
                <a href={`/product/${elem._id}`}>
                <div className='row mt-1' key={elem._id}>
                  <div className='col-lg-4 '>
                    <img src={staticurl + elem.prod_thumbnail} style={{width:"100px",border:"2px solid #e2e2e2"}} alt={elem.prod_name} />
                    </div>
                    <div className='col-lg-8 vertical-center' style={{marginLeft:"-20px"}}>
                    <div>{elem.prod_name}</div>

                      </div>

                </div>
                </a>
              ))}


              </div>

            </div>
           
          </div>
          } */}
        </li>
        
        <li className="nav-item">
          <Link className={`nav-link ${(activePage==="contact" )?"active":""}`} to="/contact" onClick={()=>handleNavbarclick("contact")}>Contact</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${(activePage==="category" )?"active":""}`} to="/category" onClick={()=>handleNavbarclick("category")}>Categories</Link>
          {/* {matches &&
          <div className='submenu p-3'>
            <div className='row'>
              <div className='col-lg-3'>
              {hoverDataGroupedForCategory && Object.entries(hoverDataGroupedForCategory).map(([brand, BrandProducts]) => (
                <div className='anchordiv' key={brand}>
                  <a href={`/category/${brand}`} onMouseEnter={(e) => handleMouseEnter2(e, BrandProducts)}>
                    {brand}
                  </a>
                  <hr />
                </div>
              ))}

            </div>

              <div className='col-lg-9'>
              {hoverCategoryDetails && hoverCategoryDetails.map(elem => (
                <a href={`/product/${elem._id}`}>
                <div className='row mt-1' key={elem._id}>
                  <div className='col-lg-4'>
                    <img src={staticurl + elem.prod_thumbnail} style={{width:"100px",border:"2px solid #e2e2e2"}} alt={elem.prod_name} />
                    </div>
                    <div className='col-lg-8 vertical-center' style={{marginLeft:"-20px"}}>
                    <div>{elem.prod_name}</div>

                      </div>

                </div>
                </a>
              ))}


              </div>

            </div>
           
          </div>
          } */}





        </li>

        
        <li className='nav-item'>
          <Link className={`nav-link ${(activePage==="recentview")?"active":""}`} onClick={()=>handleNavbarclick("recentview")} to="recentview">Recent View</Link>
        </li>

        {isLoggedIn && 
          <li className='nav-item'>
          <Link className={`nav-link ${(activePage==="adminpanel" )?"active":""}`} onClick={()=>handleNavbarclick("adminpanel")} to="/adminpanel">AdminPanel</Link>
        </li>
        }
        
      </ul>
      <form className="d-flex searchform" role="search">
      <CategoryWithSubCategorySelect 
      selectId="formSearchCategory" 
      categories={categories}
      firstOption="All Categories"
       />
          
          <input className="form-control" id="search" type="search" placeholder="Search Products" aria-label="Search" />
          <button className="px-2" onClick={(e)=>handleSearch(e)}><i className="fa-solid fa-magnifying-glass"></i></button>
          
        {/* // <button className="btn btn-outline-primary radius50" onClick={(e)=>handleSearch(e)}><i class="fa-solid fa-magnifying-glass"></i></button> */}
      </form>
    </div>
  </div>

</nav>


  )
}
