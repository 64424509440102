

function authfetch(url,navigate,options={}) {

    const authHeader = {
        "Content-Type":"application/json",
        "Authorization":localStorage.getItem("token")
    }
    const headers = Object.assign({},options.headers || {},authHeader)
    return fetch(url,Object.assign({},options,{headers})).then((response)=>{
        if (response.status === 401){
            console.log("status 401")
            localStorage.clear()
            navigate("/")
        }
        return response
    })
}


module.exports = authfetch